// hooks/useEasyEngage.js
import { useEffect } from 'react';

const useEasyEngage = (appId) => {
  useEffect(() => {
    if(process?.env?.NEXT_PUBLIC_ENV === 'PRODUCTION'){
        if (typeof window !== 'undefined') {
          console.log("EASY ENGAGE");
          // Delay loading EasyEngage by 3.5 seconds
          const timer = setTimeout(() => {
            // Setting the app_id
            window.fpsetting = { app_id: appId };

            // Dynamically load the EasyEngage script
            const script = document.createElement('script');
            script.src = 'https://stats.easyleadz.com/easyengage/io.js';
            script.async = true;
            document.head.appendChild(script);
            
            // Cleanup the script when the component unmounts
            return () => {
              document.body.removeChild(script);
            };
          }, 5000); // Delay in milliseconds (5.0 seconds)
    
          // Cleanup the timer when the component unmounts
          return () => clearTimeout(timer);
        }
    }
  }, [appId]);
};

export default useEasyEngage;
