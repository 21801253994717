import LoginReducer from "./LoginSlice";
import SignupReducer from "./SignupSlice";
import CommonReducer from "./commonSlice";

const rootReducer = {
  login: LoginReducer,
  signUp: SignupReducer,
  commonReducer: CommonReducer,
};

export default rootReducer;
