// useClarity.js
import { useEffect } from 'react';

const useClarity = (clarityProjectId) => {
  useEffect(() => {
    // Check if Clarity is enabled in your production environment
    if (process?.env?.NEXT_PUBLIC_ENV === 'PRODUCTION') {
      console.log("CLARITY");
      // Load the Clarity script
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + clarityProjectId;
        t.id = 'clarity-script';
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script');
    }
  }, [clarityProjectId]);
};

export default useClarity;
