/* eslint-disable import/no-anonymous-default-export */
export default {
  primary: {
    main: "#2A5798",
    1000: "#E9F3FF",
    900: "#FAFAFA",
  },
  info: {
    main: "#D1E5FF",
  },
  dark: {
    main: "#222222",
    1000: "#1c1b1b",
    900: "#666",
    light: "#dededf",
  },
  warning: {
    main: "#F6B333",
    light: "#fffaf0",
  },
  error: {
    main: "#df1e1f",
  },
  success: {
    main: "#03b44a",
  },
  gray: {
    main: "#707070",
  },
};
