import { createSlice } from "@reduxjs/toolkit";

const CommonSlice = createSlice({
  name: "common",
  initialState: {
    open: false,
    authTab: "Seeker",
    headerData: [],
    jobId: "",
    isOffline: false,
    keyword: "",
    salaryexpvalue: {},
    status: "",
    screen : 1,
    userdata: {},
    seeker_cv: null,
    seeker_buffer: [],
  },
  reducers: {
    setSeekerCV: (state, action) => {
      state.seeker_cv = action.payload.seeker_cv;
      state.seeker_buffer = action.payload.seeker_buffer;
    },
    setInstituteOpen: (state, action) => {
      state.open = action.payload;
    },
    updateAuthTabStatus: (state, action) => {
      state.authTab = action.payload;
    },
    storeHeaderData: (state, action) => {
      state.headerData = action.payload;
    },
    storeJobId: (state, action) => {
      state.jobId = action.payload;
    },
    storeKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    storeSalaryExpValue: (state, action) => {
      state.salaryexpvalue = action.payload;
    },
    storeStatus: (state, action) => {
      state.status = action.payload;
    },
    storeUserData: (state, action) => {
      state.userdata = action.payload;
    },
    setScreen:(state, action) =>{
      state.screen = action.payload
    }
  },
});

export const {
  setInstituteOpen,
  updateAuthTabStatus,
  storeJobId,
  storeHeaderData,
  storeKeyword,
  storeSalaryExpValue,
  storeStatus,
  storeUserData,
  setSeekerCV,
  setScreen
} = CommonSlice.actions;
export default CommonSlice.reducer;
