/* eslint-disable import/no-anonymous-default-export */
export default {
  styleOverrides: {
    root: {
      fontSize: 14,
      margin: 0,
      borderRadius: "8px",
      height: "46px",
    },
    input: {
      fontSize: 14,
      borderColor: "#dededf",
      alignItems: "center",
      borderRadius: "8px",
      padding: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
    notchedOutline: {
      "@media (max-width:767.98px)": {
        borderWidth: 0.5,
        borderColor: "#dededf",
      },
      borderWidth: 0,
    },
    multiline: {
      height: "100%",
      padding: 0,
    },
  },
};
