/* eslint-disable import/no-anonymous-default-export */
import { Inter } from "next/font/google";

const inter = Inter({
  // weight: ["300", "400", "500", "600", "700"],
  subsets: ["latin"],
  display: "swap",
});

// Define typography with fallback fonts
const typography = {
  fontFamily: `${inter.style.fontFamily}, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif`,
  fontWeightBold: 700,
  fontWeightLight: 300,
  fontWeightMedium: 500,
  fontWeightRegular: 400,
  h2: {
    fontSize: "36px",
    fontWeight: 600,
  },
  h5: {
    fontSize: "26px",
    fontWeight: 600,
  },
};

export default typography;