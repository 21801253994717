/* eslint-disable react-hooks/exhaustive-deps */

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import theme from "@/theme";
import useGTM from '../hooks/useGTM';
import useEasyEngage from '../hooks/useEasyEngage';
import useClarity from "@/hooks/useClarity";

function Providers({ children }) {
  useClarity('evjteiegnx'); // Replace with your Clarity ID
  useEasyEngage(3111704281438); // Replace with your actual app_id
  useGTM('GTM-TF583VD'); // Replace with your actual GTM ID

  return (
    <>
      <ThemeProvider theme={theme}>
        {children}
        <CssBaseline />
      </ThemeProvider>
    </>
  );
}

export default Providers;
