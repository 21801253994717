import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import { createWrapper } from "next-redux-wrapper";
import rootReducer from "./slices";

const makeStore = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
  });

const Wrapper = createWrapper(makeStore);

export default Wrapper;
