import { createSlice } from "@reduxjs/toolkit";

const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState: {
    open: false,
    loading: false,
    login: "",
    empLogin: {},
    tempForgotPasswordToken: "",
  },
  reducers: {
    setLoginOpen: (state, action) => {
      state.open = action.payload;
    },
    storeVerificationCodeData: (state, action) => {
      state.loading = false;
      state.login = action.payload;
    },
    storeLoginUserData: (state, action) => {
      state.loading = false;
      state.capture = action.payload;
    },
    storeEmployerVerificationCodeData: (state, action) => {
      state.loading = false;
      state.empLogin = action.payload;
    },
    updateEmployerForgotPpasswordToken: (state, action) => {
      state.loading = false;
      state.tempForgotPasswordToken = action.payload.data.token;
    },
    storeEmployerLoginUserData: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  storeVerificationCodeData,
  storeLoginUserData,
  storeEmployerVerificationCodeData,
  updateEmployerForgotPpasswordToken,
  setLoginOpen
} = LoginSlice.actions;
export default LoginSlice.reducer;
