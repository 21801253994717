import { createTheme } from "@mui/material/styles";
import typography from "./typography";
import palette from "./palette";
import breakpoint from "./breakpoint";
import autocomplete from "./autocomplete";
import outlinedinput from "./outlinedinput";
import helperText from "./helperText";
import button from "./button";

const theme = createTheme({
  typography: { ...typography },
  palette: { ...palette },
  breakpoints: { ...breakpoint },
  components: {
    MuiAutocomplete: { ...autocomplete },
    MuiOutlinedInput: { ...outlinedinput },
    MuiFormHelperText: { ...helperText },
    MuiButton: { ...button },
    MuiSkeleton: {
      styleOverrides: {
        root: { backgroundColor: "#D1E5FF", height: 25, transform: "unset" },
      },
    },
  },
});

export default theme;
