// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      fontSize: 14,
      margin: 0,
      borderRadius: "8px",
      borderColor: "#dededf",
    },
    inputRoot: {
      "@media (max-width:767.98px)": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: 1,
        },
        paddingLeft: 2,
        paddingRight: 2,
      },
      fontSize: 14,
      borderColor: "#dededf",
      alignItems: "center",
      borderRadius: "8px",
      padding: 0,
      paddingLeft: 10,
      paddingRight: 10,
      margin: 0,
    },
    popupIndicator: {
      marginTop: 0,
    },
  },
};
