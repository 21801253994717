// eslint-disable-next-line import/no-anonymous-default-export
export default {
  values: {
    xs: 0,
    sm: 767.98,
    md: 991.98,
    lg: 1200,
    xl: 1400,
    xxl: 1536,
  },
};
