import { createSlice } from "@reduxjs/toolkit";

const SignUpSlice = createSlice({
  name: "signUp",
  initialState: {
    open: false,
    loading: false,
    seekerSignup: {},
    employerSignup: {},
    tempSeekerToken: {},
    tempEmployerToken: {},
    explist: {},
  },
  reducers: {
    setSignUpOpen: (state, action) => {
      state.open = action.payload;
    },
    storeSignupSeekerData: (state, action) => {
      state.loading = false;
      state.tempSeekerToken = action.payload;
      state.tempEmployerToken = {};
    },
    storeSignupEmployerData: (state, action) => {
      state.loading = false;
      state.tempEmployerToken = action.payload;
      state.tempSeekerToken = {};
      state.employerSignup = {};
    },
    storeSignupEmployerFinalData: (state, action) => {
      state.loading = false;
      state.tempEmployerToken = {};
      state.employerSignup = action.payload;
    },
    storeExpList: (state, action) => {
      state.explist = action.payload;
    },
  },
});

export const {
  storeSignupSeekerData,
  storeSignupEmployerData,
  storeSignupEmployerFinalData,
  storeExpList,
  setSignUpOpen,
} = SignUpSlice.actions;
export default SignUpSlice.reducer;
