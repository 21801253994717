import { Provider } from "react-redux";
import Wrapper from "@/store";
import Providers from "@/providers";
import "@/styles/globals.css";

function App({ Component, ...rest }) {
  const { store, props } = Wrapper.useWrappedStore(rest);
  return (
    <Providers>
      <Provider store={store}>
        <Component {...props.pageProps} />
      </Provider>
    </Providers>
  );
}

export default App;
