// eslint-disable-next-line import/no-anonymous-default-export
export default {
  styleOverrides: {
    root: {
      textTransform: "none",
      borderRadius: "8px",
      boxShadow: "none"
    }, 
  },
};
