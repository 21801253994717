// hooks/useGTM.js
import { useEffect } from 'react';

const useGTM = (gtmContainerId) => {
  useEffect(() => {
    if (process?.env?.NEXT_PUBLIC_ENV === 'PRODUCTION') {
      console.log("GTM");
      // Delay loading GTM by 3.5 seconds
      const timer = setTimeout(() => {
        // Load the GTM script
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmContainerId}`;
        script.async = true;

        document.head.appendChild(script);

        // Initialize GTM
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          function gtmLoad() {
            window.dataLayer.push({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
          }
          gtmLoad();
        };
      }, 4000); // Delay in milliseconds (4.0 seconds)

      // Cleanup function
      return () => clearTimeout(timer);
    }
  }, [gtmContainerId]);
};

export default useGTM;